// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-better-way-importance-js": () => import("./../../../src/pages/a-better-way/importance.js" /* webpackChunkName: "component---src-pages-a-better-way-importance-js" */),
  "component---src-pages-a-better-way-innovative-design-js": () => import("./../../../src/pages/a-better-way/innovative-design.js" /* webpackChunkName: "component---src-pages-a-better-way-innovative-design-js" */),
  "component---src-pages-a-better-way-using-excellerator-js": () => import("./../../../src/pages/a-better-way/using-excellerator.js" /* webpackChunkName: "component---src-pages-a-better-way-using-excellerator-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-video-index-js": () => import("./../../../src/pages/demo-video/index.js" /* webpackChunkName: "component---src-pages-demo-video-index-js" */),
  "component---src-pages-development-fda-clearance-js": () => import("./../../../src/pages/development/fda-clearance.js" /* webpackChunkName: "component---src-pages-development-fda-clearance-js" */),
  "component---src-pages-development-history-js": () => import("./../../../src/pages/development/history.js" /* webpackChunkName: "component---src-pages-development-history-js" */),
  "component---src-pages-development-quick-reference-guide-js": () => import("./../../../src/pages/development/quick-reference-guide.js" /* webpackChunkName: "component---src-pages-development-quick-reference-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-womens-health-impact-cervical-cancer-screening-js": () => import("./../../../src/pages/womens-health-impact/cervical-cancer-screening.js" /* webpackChunkName: "component---src-pages-womens-health-impact-cervical-cancer-screening-js" */),
  "component---src-pages-womens-health-impact-clinical-results-js": () => import("./../../../src/pages/womens-health-impact/clinical-results.js" /* webpackChunkName: "component---src-pages-womens-health-impact-clinical-results-js" */),
  "component---src-pages-womens-health-impact-fda-validation-study-js": () => import("./../../../src/pages/womens-health-impact/fda-validation-study.js" /* webpackChunkName: "component---src-pages-womens-health-impact-fda-validation-study-js" */),
  "component---src-pages-womens-health-impact-getting-excellerator-js": () => import("./../../../src/pages/womens-health-impact/getting-excellerator.js" /* webpackChunkName: "component---src-pages-womens-health-impact-getting-excellerator-js" */),
  "component---src-pages-womens-health-impact-hpv-std-screening-js": () => import("./../../../src/pages/womens-health-impact/hpv-std-screening.js" /* webpackChunkName: "component---src-pages-womens-health-impact-hpv-std-screening-js" */),
  "component---src-pages-womens-health-impact-index-js": () => import("./../../../src/pages/womens-health-impact/index.js" /* webpackChunkName: "component---src-pages-womens-health-impact-index-js" */),
  "component---src-pages-womens-health-impact-pilot-study-js": () => import("./../../../src/pages/womens-health-impact/pilot-study.js" /* webpackChunkName: "component---src-pages-womens-health-impact-pilot-study-js" */),
  "component---src-pages-womens-health-impact-real-world-data-js": () => import("./../../../src/pages/womens-health-impact/real-world-data.js" /* webpackChunkName: "component---src-pages-womens-health-impact-real-world-data-js" */)
}

